
/*             Navigation Tabs                 */
.nav-tabs-navigation{
  text-align: center;
  border-bottom: 1px solid $medium-pale-bg;
  margin-bottom: 30px;

  .nav > .nav-item > .nav-link{
    padding-bottom: 20px;
  }
}
.nav-tabs-wrapper{
  display: inline-block;
  margin-bottom: -6px;
  position: relative;
  width: 100%;
}

.profile-content{
  & .tab-content{
    & .tab-pane{
      min-height: 200px;
    }
    & #tweets, #connections, #media{
      height: 100%;
    }
  }
}



.nav-text, .nav-icons{
  margin: $margin-bottom;

  > li > a{
    display: block;
    padding: 0px $padding-base-horizontal;
    color: $dark-gray;
    text-align: center;
    @include opacity(0.8);

    &:hover,
    &:focus{
      background-color: $transparent-bg;
      @include opacity(1);
    }
  }
  > li:first-child a{
    padding-left: 0;
  }
  > li.active a{
    color: $info-color;
  }
}
.nav-icons > li{
  display: inline-block;
  > a{
    padding: 0 10px;
    margin-bottom: 10px;
  }
  > a i{
    font-size: $font-size-h4;
    margin-bottom: 10px;
    width: $font-size-h4;
  }
}
.nav-icons.nav-stacked > li{
  display: block;
  > a {
    margin-bottom: 20px;
  }
}
.nav-blue > li.active a{
  color: $primary-color;
}
.nav-azure > li.active a{
  color: $info-color;
}
.nav-green > li.active a{
  color: $success-color;
}
.nav-orange > li.active a{
  color: $warning-color;
}
.nav-red > li.active a{
  color: $danger-color;
}

.nav-text{
  margin: $margin-bottom;

  > li > a{
    font-size: $font-size-h6;
    text-transform: uppercase;
    padding: 3px 0;
    text-align: left;
    font-weight: $font-weight-semi;

  }
  > li:first-child > a{
    padding-top: 0;
  }
  h4{
    margin-top: 0;
  }
}

.nav-text:not(.nav-stacked){
  > li{
    display: inline-block;
  }
  > li > a{
    margin-right: 15px;
  }
}

.tab-space {
  padding: 20px 0 50px;
}

// Vertical navs
.verical-navs .nav-stacked .nav-link.active:before,
.verical-navs .nav-stacked .nav-link.active:hover:before,
.verical-navs .nav-stacked .nav-link.active:focus:before,
.verical-navs .nav-stacked .nav-link.active.show:before{
  border-right: 11px solid #F1EAE0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 20px;
}

.verical-navs .nav-stacked .nav-link.active:after,
.verical-navs .nav-stacked .nav-link.active:hover:after,
.verical-navs .nav-stacked .nav-link.active:focus:after,
.verical-navs .nav-stacked .nav-link.active.show:after{
  border-right: 11px solid $white-color;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  right: -1px;
  bottom: 20px;
}
#second-tabs{
  margin-left: 20px;
}

.nav-tabs-navigation.verical-navs {
  border-right: 1px solid #F1EAE0;
  font-size: 16px;
  border-bottom: none;
}
