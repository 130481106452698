.TagManagement {
    margin: 1rem;
    height: 100vh;
  }

  .TagManagement .wrap-title {
    display: flex;
    align-items: center;
  }
  .TagManagement .wrap-title h2 {
    margin-bottom: 0;
  }

  .TagManagement .wrap-title button {
    margin-left: 5px;
  }

  .TagManagement .tag-title-wrap {
    display: flex;
  }

  .ht_hyperNode > div {
    cursor: pointer;
  }


.rst__node{
  height: 40px !important;
}
.rst__moveHandle, .rst__loadingHandle{
  width: 25px;
  background-size:cover;
}
.rst__rowTitle{
  font-size: 12px;
  font-weight: unset;
}
.rst__rowWrapper{
  padding: 5px 5px 5px 0;
}
