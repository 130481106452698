.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.photo__input {
  position: relative;

  input {
    height: 44px;
  }
}

.z-100 {
  z-index: 100;
}

.overflowY {
  overflow-y: scroll;
  height: calc(100vh - 230px);
}

.photo__input__icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #808089;
}

.photo__filter {
  display: flex;
  align-items: center;
}

.photo__filter__container {
  position: sticky;
  top: 50px;
  z-index: 20;
  background: white;
  padding: 24px 0;
  //display: flex;
  align-items: center;
}

.photo__btn {
  background: transparent;
  border-radius: 4px;
  border: 1px solid #DDDDE3;
  box-sizing: border-box;
  cursor: pointer;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  margin-left: 12px;

  &--active {
    border-color: #31BEC7;
    box-sizing: border-box;

    span {
      margin-left: 0 !important;
    }
  }

  span {
    margin-left: 6px;
  }
}

.photo__btn__close {
  margin-left: 8px;
}

.photo__col--last {
  display: flex !important;
  justify-content: flex-end;

  button {
    //margin: 0 !important;
  }
}

.mt-6 {
  margin-top: 24px;
  display: block;
}

.photo__form {
  background: #F5F5FA;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.photo__form__header {
  background: #38383D;
  padding: 24px 0;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.photo__filter__date {
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.photo__filter__title_description {
  margin-top: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.photo__filter__include_without_tags{
  margin-top: 8px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.react-datepicker-wrapper {
  width: 100%;

}

.react-datepicker__input-container {
  input {
    padding: 10px;
    border: 1px solid #C4C4CF;
    border-radius: 4px;
    width: 100%;
  }
}

.photo__datepicker__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.photo__datepicker__label {
  margin-top: 8px;
}

.photo__datepicker {
  position: relative;

  img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.photo__filter__tag {
  background: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.photo__tag {
  border: 1px solid #DDDDE3;
  border-radius: 8px;
  margin: 6px 0;
}

.photo__tag__container,
.photo__tag__list {
  display: flex;
  align-items: center;
}

.photo__tag__container {
  &>span {
    margin-top: 8px;
    display: block;
  }
}

.photo__tag__list {
  flex-wrap: wrap;
}

.photo__tag--selected {
  padding: 6px 8px;
  border-radius: 4px;
  background-color: #FFF5EB;
  display: flex;
  align-items: center;
  width: max-content;
  color: #FC820A;
  margin-left: 8px;
  margin-top: 8px;

  span {
    font-weight: 500;

  }

  img {
    margin-left: 6px;
  }
}

.photo__tag__header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 12px 16px;

  &>span {
    font-weight: 600;
  }
}

.photo__tag__child {
  margin: 12px 0;
  display: flex;
  align-items: center;

  span {
    margin-left: 12px;
  }
}

.photo__filter__actions {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  background: white;
  padding: 24px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.photo__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  column-gap: 24px;
  row-gap: 24px;
}

.photo__item {
  width: 160px;
  height: 160px;
  object-fit: cover;

  &--active {
    cursor: pointer;
    border: 2px solid #31BEC7;
    box-sizing: border-box;
  }
}

.photo__list__group {
  margin: 6px 0;
  margin-top: 24px;
}

.photo__list__title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.photo__list__date {
  color: #38383D;
  font-weight: 600;
}

.photo__list__stats {
  color: #808089;
  margin-left: 16px;
}

.photo__empty {
  margin: 72px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;

  img {
    width: 180px;
    object-fit: contain;
    margin-bottom: 16px;
  }

  span {
    color: #808089;
    line-height: 24px;
    text-align: center;
  }
}

.photo__item__checkbox {
  position: absolute;
  top: 12px;
  right: 12px;
}

.photo__options {
  display: flex;
  align-items: center;

  button:not(:first-child) {
    margin-left: 8px;
  }

  button {
    width: 48px;
    height: 48px;
  }
}

.photo__options__toast {
  z-index: 999999;
  position: fixed;
  bottom: 30px;
  left: calc(50% - 170px);
  display: flex;
  align-items: center;
  padding: 12px 16px;
  min-width: 340px;
  border-radius: 6px;
  background-color: #2DC26D;
  box-shadow: 0px 8px 16px rgba(40, 40, 43, 0.1);
  span {
    margin-left: 8px;
  }
}

.photo__options__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.photo__tag__sublevel {
  border-top: 1px solid #DDDDE3;
  padding: 12px 24px 24px;

  &__list {
    padding: 0 8px;
  }
}

.photo__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  column-gap: 16px;
  row-gap: 12px;
  margin-top: 12px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 12px;
  }
}

.photo__container__item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  background: #F5F5FA;

  img {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
}

.photo__modal {
  padding: 24px;
}

.photo__modal__title {
  font-size: 20px;
  font-weight: 600;
}

.photo__modal__container {
  margin-top: 32px;
}

.photo__modal__tags {
  margin-top: 32px;
}

.photo__modal__btn {
  width: 100%;
}

.export__modal {
  padding: 24px;
}

.export__modal__body {
  margin-top: 32px;
}

.export__header {
  font-weight: 600;
  font-size: 20px;
}

.export__title {
 font-weight: 400;
 font-size: 16px;
 margin-bottom: 4px;
}

.export__modal__list {
  display: flex;
  gap: 10px;
}

.export__modal__item {
  background-color: #F5F5FA;
  width: 100%;
  padding: 12px;
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  position: relative;

}

.export__modal__item__header {
  icon {
    position: absolute;
    right: 12px;
    top: 15px;
    cursor: pointer;
  }
  img {
    object-fit: cover;
    width: 20px;
    height: 20px;
  }
}

.export__modal__checklist {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.export__modal__checklist__item {
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
  }
  label{
    margin-bottom: 0;
    margin-left: 4px;
  }
}

.export__modal__photos {
  margin-top: 24px;
}


.format-option-selection-selectedBox {
  background-color: #F5FEFF !important;
  border: 1px solid #31BEC7 !important;
  color: #26A5AD !important;
}


.format-option-selection {
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  padding: 6px 12px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.format-option-icon {
  color: #31BEC7;
  font-size: 25px
}

.format-option-box {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  max-width: 215px;
  height: 120px;
  background: #F5F5FA;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 1;

}

.format-option-row{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 528px;
  height: 92px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
