.PlanManagement {
    margin: 2rem;
    height: 50vh;
  }
  
  .PlanManagement .wrap-title {
    display: flex;
    align-items: center;
  }
  .PlanManagement .wrap-title h2 {
    margin-bottom: 0;
  }
  
  .PlanManagement .wrap-title button {
    margin-left: 5px;
  }
  
  .PlanManagement .tag-title-wrap {
    display: flex;
  }
  
  .ht_hyperNode > div {
    cursor: pointer;
  }